import { MutatorCallback, mutate } from 'swr'
import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import { PostInterface } from 'modules/community/types/post-interface'

export interface GetPostActionsProps {
  communityPath: string
  topicPath: string
  postPath: string
}

export const getPostActions = ({ communityPath, topicPath, postPath }: GetPostActionsProps) => {
  const globalMutatePost = (mutator: MutatorCallback<PostInterface>) =>
    mutate(`${COMMUNITY_API_PREFIX}/${communityPath}/topic/${topicPath}/post/${postPath}`, mutator)

  return { globalMutatePost }
}
