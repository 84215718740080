import React, { useEffect, useState } from 'react'
import throttle from 'lodash.throttle'
import ChevronDownIcon from 'shared/icons/chevron-down-icon'

export const ScrollToTopButton: React.FC = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false)

  useEffect(() => {
    const handleScroll = throttle(() => {
      setShowScrollToTop(window.scrollY > 300)
    }, 200)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  if (!showScrollToTop) {
    return null
  }

  return (
    <button
      onClick={scrollToTop}
      className="fixed bottom-4 right-4 flex h-10 w-10 items-center justify-center rounded-full bg-darkblue text-white shadow-md"
    >
      <ChevronDownIcon className="h-3.5 w-3.5 rotate-180" />
    </button>
  )
}
