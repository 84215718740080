import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import { httpClient } from 'shared/api/http-client'
import {
  CommunityInterface,
  CreateCommunityInterface,
} from 'modules/community/types/community-interface'
import { PostInterface } from 'modules/community/types/post-interface'

export function updateCommunitySettings(communityPath: string, data: CreateCommunityInterface) {
  return httpClient.patch<CommunityInterface>(
    `/api/community/community/${communityPath}/settings`,
    data,
  )
}

export function joinCommunity(communityPath: string) {
  return httpClient.get<CommunityInterface>(`${COMMUNITY_API_PREFIX}/${communityPath}/join`)
}

export function registerCommunity(email: string, communityUrl: string) {
  return httpClient.post(`/api/register`, {
    email,
    communityUrl,
  })
}

export function createPassword(password: string, token: string) {
  return httpClient.post(`/api/registration/confirm/${token}`, {
    plainPassword: {
      first: password,
      second: password,
    },
  })
}

export function login(email: string, password: string, locale: string) {
  return httpClient.post(`/dashboard/${locale}/login`, {
    email,
    password,
  })
}

export function likePost(postId: PostInterface['id']) {
  return httpClient.post(`/api/community/post/${postId}/like`)
}

export function unlikePost(postId: PostInterface['id']) {
  return httpClient.delete(`/api/community/post/${postId}/remove-like`)
}
