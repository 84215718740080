import { useRollbar } from '@rollbar/react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { KeyedMutator } from 'swr'
import { InternalError } from 'shared/errors/internal-error'
import * as postsApi from 'modules/community/api/posts-api'
import { PostInterface } from '../types/post-interface'

interface UseCommentsInfoParams {
  mutatePosts: KeyedMutator<PostInterface[]>
  postIds?: number[]
}

export default function useCommentsInfo({ mutatePosts, postIds }: UseCommentsInfoParams) {
  const { t } = useTranslation()
  const rollbar = useRollbar()

  const loadPostsInfo = useCallback(
    async (postIds: number[]) => {
      try {
        const { data: postsCommentsInfo } = await postsApi.getPostsCommentsInfo(postIds)
        await mutatePosts(
          posts =>
            posts &&
            posts.map(post => {
              const postInfo = postsCommentsInfo?.items?.find(({ postId }) => postId === post.id)

              if (!postInfo && postIds.includes(post.id))
                return { ...post, postCommentsInfo: { ok: false } }

              if (postInfo) return { ...post, postCommentsInfo: { ok: true, value: postInfo } }

              return post
            }),
        )
      } catch (error) {
        if (error instanceof InternalError) {
          rollbar.error(error)
        } else {
          rollbar.log(JSON.stringify(error))
        }

        await mutatePosts(
          posts =>
            posts &&
            posts.map(post =>
              postIds.includes(post.id)
                ? { ...post, postCommentsInfo: { ok: false, error: error as Error } }
                : post,
            ),
        )

        console.log(`--e--`, error)
      }
    },
    [mutatePosts, t, rollbar],
  )

  useEffect(() => {
    if (!!postIds?.length) {
      loadPostsInfo(postIds)
    }
  }, [postIds, loadPostsInfo])
}
